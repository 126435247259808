import React from 'react';
import styled from 'styled-components';
import { Description, H1, H3 } from '@/components/Typography';
import ButtonDefault from '@/components/ButtonDefault';
import viewport1Img from '../../assets/img/inventoryStockManagementVp1.png';
import Section from '@/components/Section';
import FirstViewport from '@/components/FirstViewport';
import StepBlueCheckCard from '@/components/StepBlueCheckCard';
import BigPurpleCard from '@/components/BigPurpleCard';
import { InventoryStockManagementPageContent } from '@/pages/inventory-and-stock-management';
import ReversibleCardsComponent from '@/components/ReversibleCardsComponent';

const FirstVpWrapper = styled.div`
  background: linear-gradient(118.47deg, #f8f9fa 3.57%, #e4e6f0 100%);
`;

const SecondViewport = styled(Section)`
  background: linear-gradient(#ffffff, #e7edf3);
  padding-bottom: 80px;
  @media (max-width: 800px) {
    padding-bottom: 1rem;
  }
`;

const DefaultViewport = styled(SecondViewport)<{
  withoutPaddingTop?: boolean;
  withoutPaddingBottom?: boolean;
}>`
  background: transparent;
  ${({ withoutPaddingTop }) => withoutPaddingTop && `padding-top: 0;`}
  ${({ withoutPaddingBottom }) => withoutPaddingBottom && `padding-bottom: 0;`}
`;

const StyledDescription = styled(Description)`
  :first-of-type {
    margin-top: 1rem;
  }
`;

const ViewPortsWrapper = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  gap: 80px;
`;

const StepsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 30px;
  margin-top: 40px;
`;

interface ProductInformationManagementProps {
  content: InventoryStockManagementPageContent;
}

const InventoryAndStockManagementContainer: React.FunctionComponent<
  ProductInformationManagementProps
> = ({ content: { viewport1, viewport2, viewport3, viewport4 } }) => (
  <>
    <FirstVpWrapper>
      <FirstViewport img={viewport1Img}>
        <H1>
          {viewport1.title1}
          <span className="accent-text">{viewport1.title2}</span>
          {` `}
          {viewport1.title3}
        </H1>
        <StyledDescription>{viewport1.description}</StyledDescription>
        <ButtonDefault to={viewport1.button.url}>
          {viewport1.button.label}
        </ButtonDefault>
      </FirstViewport>
    </FirstVpWrapper>

    <SecondViewport>
      <H3 style={{ maxWidth: 768, margin: `0 auto`, textAlign: `center` }}>
        {viewport2.title}
      </H3>
      <StepsWrapper>
        {viewport2.carouselItems.map((item) => (
          <StepBlueCheckCard
            title={item.title}
            description={item.description}
          />
        ))}
      </StepsWrapper>
    </SecondViewport>

    <ViewPortsWrapper>
      <DefaultViewport withoutPaddingBottom>
        <ReversibleCardsComponent cards={viewport3.cards} />
      </DefaultViewport>

      <DefaultViewport withoutPaddingTop>
        <BigPurpleCard
          title={viewport4.title}
          leftColumn={viewport4.leftColumn}
          rightColumn={viewport4.rightColumn}
          bottomText={viewport4.bottomText}
          button={viewport4.button}
        />
      </DefaultViewport>
    </ViewPortsWrapper>
  </>
);

export default InventoryAndStockManagementContainer;
